@import './prismjs';

@font-face {
    font-family: 'Rajdhani';
    src: local('Rajdhani'), url(/fonts/Rajdhani/Rajdhani-Medium.ttf) format('truetype');
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: #f4f6f8;
}

a {
    text-decoration: none;
}

:not(pre)>code {
    font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
    background-color: #eeeeee;
    padding: 2px 4px;
    direction: ltr;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    font-size: 14px;
}

iframe {
    display: none;
}

#root {
    height: 100vh;
}